<template>
  <div>首页</div>
</template>

<script>
export default {
  name: "Main"
}
</script>

<style lang="less">

</style>